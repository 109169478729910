<template>
    <template v-if="entry.dateRange">
        <template v-if="entry.dateRange.start === entry.dateRange.end">
            {{ formatDate(entry.dateRange.start) }}
        </template>

        <template v-else-if="entry.dateRange.start && today < new Date(entry.dateRange.start)">
            {{ `${t('start')} ${formatDate(entry.dateRange.start)}` }}
        </template>

        <template v-else-if="entry.dateRange.end">
            {{ `${t('stop')} ${formatDate(entry.dateRange.end)}` }}
        </template>
    </template>

    <template v-else-if="entry.vacancyStartDate">
        {{ `${t('from')} ${formatDate(entry.vacancyStartDate)}` }}
    </template>
</template>

<script setup>
import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
const { t, d } = useI18n();

const props = defineProps({
    entry: {
        type: Object,
        required: true,
        default: () => {}
    }
});

const today = new Date();
today.setUTCHours(0, 0, 0, 0);

/**
 * IMPORTANT: JCK specifically requested to ALWAYS display the date in Amsterdam time.
 * This means people in New York will also see the dutch date / time, not the one
 * in their own timezone.
 */
const convertToAmsterdamTime = function(ISODateString) {
    if (!ISODateString) {
        return;
    }

    const dateObject = parseISO(ISODateString);
    const amsterdamTime = utcToZonedTime(dateObject, 'Europe/Amsterdam');
    return format(amsterdamTime, 'yyyy-MM-dd HH:mm:ss', { timeZone: 'Europe/Amsterdam' });
};

const formatDate = function(ISODateString) {
    return d(convertToAmsterdamTime(ISODateString), 'dayAndMonth');
};
</script>

<i18n>
    {
        "nl": {
            "start": "vanaf",
            "stop": "tot",
            "from": "per"
        },
        "en": {
            "start": "from",
            "stop": "till",
            "from": "from"
        }
    }
</i18n>
